import React, { useState, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import {
  FloatingLabel,
  Button,
  Spinner,
  Alert,
  Container,
} from "react-bootstrap";
import { graphql } from "gatsby";
import Turnstile from "react-turnstile";
import { PhoneInput } from "react-international-phone";
import PageBanner from "../components/elements/PageBanner";
import Layout from "../components/common/Layout";
import Seo from "../components/meta/Seo";
import components from "../components/slices/index";
import { linkResolver } from "../utilities/prismic-linkResolver";
import { constructActiveDoc } from "../utilities/helpers";

import "react-international-phone/style.css";

import "../styles/pages/complaint_form.scss";

const ComplaintPage = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const mydata = page.data;
  const form = mydata.form_values[0];
  const language = page.lang;
  let validMessage = "";

  const [phone, setPhone] = useState("");
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState();
  const [validated, setValidated] = useState(false);

  const messages = {
    "en-us": {
      success: "Thank you for your message.",
      error: "Something went wrong. Please try again later.",
    },
    "el-gr": {
      success: "Σας ευχαριστούμε για το μήνυμά σας.",
      error: "Κάτι πήγε στραβά. Παρακαλώ δοκιμάστε ξανά αργότερα.",
    },
  };

  if (language === "en-us") {
    validMessage = "Please fill out this field!";
  } else {
    validMessage = "Παρακαλώ συμπληρώστε αυτό το πεδίο!";
  }

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.currentTarget.checkValidity() === false) {
      setValidated(true);
      return;
    }

    setLoading(true);

    fetch("/api/forms/submit", {
      method: "POST",
      body: new FormData(e.target),
    })
      .then((res) => res.json())
      .then((res) => {
        const { success } = res;

        if (success) {
          e.target.reset();
          setSubmitStatus({
            message: messages[language].success,
            type: "success",
          });
        } else {
          setSubmitStatus({
            message: messages[language].error,
            type: "danger",
          });
        }
      })
      .catch(() => {
        setSubmitStatus({
          message: "Something went wrong.",
          type: "danger",
        });
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div style={{ overflowX: "hidden" }} className={page.type}>
      <Seo page={page} />
      <Layout activeDocMeta={constructActiveDoc(page)}>
        <PageBanner
          title={page.data.title.text}
          bgImage={page.data.banner_image}
          bgImageMobile={page.data.image_mobile}
          page={page}
        />
        <Container fluid className="complaint-bg-pattern">
          <div className="wrapper-c">
            <div className="complaints-container">
              <div className="row inner justify-content-center">
                <div className="col-12 col-md-8 col-xl-6">
                  <Form
                    className="complaint-form"
                    onSubmit={onSubmit}
                    name="complaint-form"
                    noValidate
                    validated={validated}
                  >
                    <input type="hidden" name="type" value="complaints" />
                    <input type="hidden" name="Form" value="ΦΟΡΜΑ ΠΑΡΑΠΟΝΩΝ" />

                    <Form.Group className="mb-2" controlId="formName">
                      <Form.Label visuallyHidden="true">
                        {form.name}*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`${form.name}*`}
                        name="Name"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {validMessage}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formLastName">
                      <Form.Label visuallyHidden="true">
                        {form.last_name}*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`${form.last_name}*`}
                        name="Last_Name"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {validMessage}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formEmail">
                      <Form.Label visuallyHidden="true">
                        {form.email}*
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={`${form.email}*`}
                        name="Email"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {validMessage}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formPhone">
                      <PhoneInput
                        defaultCountry="gr"
                        value={phone}
                        enableSearch
                        onChange={(phone) => setPhone(phone)}
                        inputProps={{ name: "Phone", required: "true" }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {validMessage}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="my-2" controlId="formRadioButton">
                      <Form.Label className="mb-0 mt-3">
                        <PrismicRichText
                          field={form.radiobutton_text.richText}
                        />
                      </Form.Label>
                      <div className="d-flex">
                        {[`${form.radiobutton1}`, `${form.radiobutton2}`].map(
                          (option, idx) => (
                            <Form.Check
                              key={option}
                              type="radio"
                              id={`radio-${idx}`}
                              label={option}
                              value={option}
                              name="Client"
                              className="me-4"
                            />
                          )
                        )}
                      </div>
                    </Form.Group>

                    <FloatingLabel controlId="formTextarea" className="mb-2">
                      <Form.Control
                        as="textarea"
                        placeholder={`${form.message}*`}
                        name="Message"
                        style={{ height: "200px", width: "100%" }}
                        required
                      />
                      <Form.Control.Feedback type="invalid" className="mb-3">
                        {validMessage}
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <Form.Group
                      className="mb-5 formBasicCheckbox"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Label className="d-flex align-items-center gap-2 position-relative">
                        <Form.Check
                          type="checkbox"
                          name="Terms"
                          value="accepted"
                          required
                          feedback={validMessage}
                          feedbackType="invalid"
                        />
                        <div className="rte slab-400">
                          <PrismicRichText
                            field={form.checkbox_content.richText}
                            linkResolver={linkResolver}
                          />
                        </div>
                      </Form.Label>
                    </Form.Group>

                    <Turnstile
                      sitekey={process.env.GATSBY_TURNSTILE_SITE_KEY}
                      theme="dark"
                      onError={() => setDisable(true)}
                      onExpire={() => setDisable(true)}
                      onVerify={() => setDisable(false)}
                      className="mb-2"
                    />
                    <Button
                      type="submit"
                      disabled={disable || loading}
                      className="d-block mx-auto book-now-outline"
                      variant="secondary"
                    >
                      {form.submit_button_label}
                      {loading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ms-2"
                        />
                      )}
                    </Button>
                  </Form>
                  {submitStatus && (
                    <Alert
                      variant={submitStatus.type}
                      className="my-3 text-center"
                      onClose={() => setSubmitStatus()}
                      dismissible
                    >
                      {submitStatus.message}
                    </Alert>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>

        <SliceZone components={components} slices={page.data.body} />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query complaintPageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicComplaintPage(lang: { eq: $lang }) {
      ...prismicComplaintPageFragment
    }
  }
`;

export default ComplaintPage;
